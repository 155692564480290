import React, { useEffect, useState } from "react";
import liff from "@line/liff";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 100vw; // Ensures that the width does not exceed the viewport width
  height: 100vh; // Use the viewport height to limit the container height
  box-sizing: border-box; // Include padding in the height calculation
  overflow-y: auto; // Allows vertical scrolling within the container if necessary
`;

const LiffInit = ({ children }) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: "2004697545-k46Q3GE3" });
        if (liff.isLoggedIn()) {
          const userProfile = await liff.getProfile();
          setProfile(userProfile);
        } else {
          liff.login();
        }
      } catch (err) {
        console.error("LIFF Initialization failed", err);
      }
    };

    initializeLiff();
  }, []);

  return (
    <div className="App">
      <Container>
        <h1>SWIPY收款狀態查詢</h1>
        {profile && (
          <div className="profile">
            <img
              src={profile.pictureUrl}
              width="50"
              alt="profileUrl"
              loading="lazy"
            />
            {/* <p data-testid="userId">userId: {profile.userId}</p> */}
            <p data-testid="displayName">Hi, {profile.displayName}</p>
          </div>
        )}
        {children}
      </Container>
    </div>
  );
};

export default LiffInit;
