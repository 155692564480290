import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

// Responsive container
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 100vw; // Ensures that the width does not exceed the viewport width
  height: 100vh; // Use the viewport height to limit the container height
  box-sizing: border-box; // Include padding in the height calculation
  overflow-y: auto; // Allows vertical scrolling within the container if necessary
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 20px;
  width: 90%; // Adjusts width relative to the parent container
  font-size: 16px; // Enhances readability on small devices
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: #00b900;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 90%; // Matches the input width
  font-size: 18px; // Larger font for easier interaction
  &:hover {
    background-color: #007700;
  }
`;

const Table = styled.table`
  width: 70%; // Responsive width to fit within the Container
  max-width: 100%; // Ensures it does not exceed the container's width
  margin-top: 20px;
  border-collapse: collapse;
  font-size: 10px;
  th,
  td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  th {
    background-color: #f2f2f2;
  }
  overflow-x: auto; // Allows horizontal scrolling for the table alone
`;

const LoadingBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #00b900;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.4s ease;

  &.loading {
    width: 100%;
  }

  &.not-loading {
    width: 0%;
  }
`;

const Message = styled.div`
  color: red;
  margin: 10px;
  font-size: 16px;
  width: 90%; // Ensure it fits within the container
  text-align: center;
`;

const SearchForm = () => {
  const [amount, setAmount] = useState("");
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  const handleSearch = async () => {
    setIsLoading(true); // Start loading
    try {
      const { data } = await axios.post(
        "https://api.h-and.world/esafe/double_check",
        { amount }
      );
      setResults(data.results);
      setMessage(data.messages);
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage("取得資料失敗，請稍後再試");
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };
  const translateStatus = (status) => {
    return status === "00" ? "成功" : "失敗";
  };

  return (
    <Container>
      {isLoading && <LoadingBar className="loading" />}
      {!isLoading && <LoadingBar className="not-loading" />}
      <Input
        type="number"
        placeholder="Enter amount"
        min="2"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <Button onClick={handleSearch}>輸入「訂單金額」搜尋</Button>
      {message && <Message>{message}</Message>}
      {results.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>訂單編號</th>
              <th>金額</th>
              <th>交易時間</th>
              <th>狀態</th>
              <th>末四碼</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <tr key={result.paymentId}>
                <td>{result.paymentId}</td>
                <td>{result.amount}</td>
                <td>{result.txTime}</td>
                <td>{translateStatus(result.status)}</td>
                <td>{result.last4CC}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default SearchForm;
