import React from "react";
import LiffInit from "./LiffInit";
import SearchForm from "./SearchForm";

function App() {
  return (
    <LiffInit>
      <div className="App">
        <SearchForm />
      </div>
    </LiffInit>
  );
}

export default App;
